import GameState      from "./game/GameState"
import Log            from "./game/Log"
import Runtime        from "./game/Runtime"
import ImagePreloader from "./game/ImagePreloader"

window.addEventListener("DOMContentLoaded", () => {
  const log = new Log({ window: window })
  const gameState = new GameState({
    window: window,
    log: log,
  })

  if ( document.querySelector("[data-end-of-game]") ) {
    if (!gameState.isEndOfGame()) {
      window.location = "/"
    }
  }

  const version = document.querySelector("[data-version]")
  if (version) {
    version.textContent = `Version: ${Runtime.instance().version}`
    version.classList.add("db")
  }
  else {
    console.log(`Version ${Runtime.instance().version}`)
  }

  const preloadImages = document.querySelector("[data-preload-images]")
  if (preloadImages) {
    const imagePreloader = new ImagePreloader({ window: window, log: log, worldId: "starlight" })
    imagePreloader.preloadImages().then( () => {
      console.log("Images pre-loaded")
    }).catch( (error) => {
      console.warn("While pre-loading images: %o",error)
    })
  }
  else {
    console.log("Not preloading images")
  }


  const navIfSave      = document.querySelector("[data-if-save-game]")
  const navIfNoSave    = document.querySelector("[data-if-no-save-game]")
  const navIfEndOfGame = document.querySelector("[data-if-end-of-game]")

  const startOver = navIfSave ? navIfSave.querySelector("[data-start-over]") : undefined
  if (navIfSave && navIfNoSave && navIfEndOfGame && startOver) {
    if (gameState.isEndOfGame()) {
      navIfNoSave.classList.add("dn")
      navIfNoSave.classList.remove("flex")
      navIfEndOfGame.classList.add("flex")
      navIfEndOfGame.classList.remove("dn")
    }
    else if (gameState.isNew()) {
      // do nothing
    }
    else {
      navIfSave.classList.remove("dn")
      navIfSave.classList.add("flex")
      navIfNoSave.classList.add("dn")
      navIfNoSave.classList.remove("flex")
      startOver.addEventListener("click", (event) => {
        if (confirm("This will erase your saved location")) {
          gameState.clear()
        }
        else {
          event.preventDefault()
        }
      })
    }
    const startOverLink = navIfEndOfGame.querySelector("a")
    if (startOverLink) {
      startOverLink.addEventListener("click", (event) => {
        event.preventDefault()
        gameState.clear()
        setTimeout(() => {
          window.location = startOverLink.href
        }, 100)
      })
    }
    else {
      console.warn("Odd - there is no Start Over link, so you are stuck having ended the game")
    }
  }
  else {
    console.warn("Problem: Could not find [data-if-save-game] or [data-if-no-save-game]")
  }
})
