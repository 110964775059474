import LocalStorage from "./LocalStorage"

class Env {
  constructor(string) {
    if ( (string === "development") ||
         (string === "production") ) {
      this.string = string
    }
    else {
      throw `'${string}' is not a valid ENV`
    }
  }

  isDev()        { return this.string == "development" }
  isProduction() { return this.string == "production" }

  toString() { return this.string }
}

const NULL_OBSERVER = {
  start:() => {},
}

class ConsolePerformanceObserver {
  constructor() {
    this.performanceObserver = new PerformanceObserver( (list,observer) => {
      list.getEntries().forEach( (entry) => {
        console.log(`${entry.name} ${entry.duration}\n%o`, entry.detail)
      })
    })
  }
  start() {
    this.performanceObserver.observe({ entryTypes: ["measure", "mark"] })
  }
}

/* Captures all runtime configuration of the game/engine
 * that is not part of the game state of the person playing the game.
 */
export default class Runtime {
  static instance() {
    if (!this._instance) {
      this._instance = new Runtime()
    }
    window.StarlightDawn = window.StarlightDawn || {}
    window.StarlightDawn.runtime = this._instance
    return this._instance
  }
  static env() {
    return this.instance().env
  }
  static debugMethods() {
    return this.env().isDev()
  }
  static measurePerformance() {
    return true
  }
  constructor() {
    if (typeof ENV !== "undefined") {
      this.env = new Env(ENV)
    }
    else {
      throw "ENV was not defined"
    }
    if (typeof APP_VERSION !== "undefined") {
      this.version = APP_VERSION
    }
    else {
      throw "APP_VERSION was not set"
    }
    if (typeof FORCE_SLOW_NETWORK !== "undefined") {
      this.forceSlowNetwork = FORCE_SLOW_NETWORK == "true"
    }
    else {
      this.forceSlowNetwork = false
    }
    this.localStorage = new LocalStorage({namespace: "Runtime", window: window})

    this.measureAllMethods                 = this.localStorage.loadBoolean("measureAllMethods", this.env.isDev() )
    this.measurePerformanceCriticalMethods = this.localStorage.loadBoolean("measurePerformanceCriticalMethods", true)
    this.performanceObserverName           = this.localStorage.loadString("performanceObserverName") || (
      this.env.isDev() ? "Console" : "Null"
    )
  }

  performanceObserver() {
    if (typeof PerformanceObserver === "undefined") {
      return NULL_OBSERVER
    }

    if (this.performanceObserverName === "Console") {
      return new ConsolePerformanceObserver()
    }
    else {
      return NULL_OBSERVER
    }
  }

  saveConfig() {
    this.localStorage.save("measureAllMethods", this.measureAllMethods)
    this.localStorage.save("measurePerformanceCriticalMethods", this.measurePerformanceCriticalMethods)
    this.localStorage.save("performanceObserverName", this.performanceObserverName)
  }

  _booleanConfigValue(name, valueWhenNotOtherwiseDefined) {
    const value = this._configValue(name, valueWhenNotOtherwiseDefined)
    return value === "true" || value === true
  }
  _configValue(name, valueWhenNotOtherwiseDefined) {
    if (typeof window !== "undefined") {
      if (window.localStorage) {
        const value = window.localStorage.getItem(this._key(name))
        if (value) {
          return value
        }
      }
    }
    return valueWhenNotOtherwiseDefined(name)
  }

  _key(name) {
    return `StarlightDawn:Runtime:${name}`
  }

}
