import Loader from "./Loader";

export default class ImagePreloader {
  constructor({window, log, worldId }) {
    this.loader  = new Loader({window, log})
    this.log     = log
    this.worldId = worldId
  }
  preloadImages() {
    const logger = this.log.measurePerformance("preloadImages") 
    return this.loader.load(`/worlds/${this.worldId}/tiles.json`).then( (tiles) => {
      if (tiles.tiles) {
        return Promise.all(tiles.tiles.map( (tile) => {
          const details = []
          const numbered = tile.match(/\-(\d+).png$/)
          const first    = tile.match(/\-1.png$/)
          if (first || !numbered) {
            const img = new Image()
            return new Promise((resolve, reject) => {
              img.addEventListener("load", () => {
                details[tile] = "loaded"
                logger.addDetails(details)
                return resolve()
              })
              img.addEventListener("error", (error) => {
                details[tile] = error.message
                logger.addDetails(details)
                return reject(error)
              })
              img.src = tile
            })
          }
          else {
            details[tile] = "skipped"
            logger.addDetails(details)
            return Promise.resolve()
          }
        })).finally( () => logger.end() )
      }
      else {
        logger.end({ error: "JSON had no tiles element" })
        return Promise.reject("JSON had no tiles element")
      }
    }).catch( (error) => {
      logger.end()
      return Promise.reject(error)
    })
  }
}
