import LocalStorage from "./LocalStorage"
import Runtime from "./Runtime"

class State {
  constructor({mapName, travelerX, travelerY, traveler, endOfGame}) {
    this.mapName    = mapName
    this.travelerX  = travelerX
    this.travelerY  = travelerY
    this.traveler   = traveler
    this.endOfGame  = !!endOfGame
  }
  isNew() {
    return !(this.mapName && this.travelerX && this.travelerY && this.traveler)
  }
}

class BaseStateStore {

  clear() { this.save(new State({})) }
}

class UrlDebugStateStore extends BaseStateStore {
  constructor({window}) {
    super()
    this.window = window
  }
  load() {
    return this._parseHash(
      (this.window.location.hash || "").replace(/^#/,"")
    )
  }

  _parseHash(hash) {
    const hashParts = hash.split(/:/)
    return new State({
      mapName: hashParts[0],
      travelerX: this._parseRealInt(hashParts[1]),
      travelerY: this._parseRealInt(hashParts[2]),
      traveler: hashParts[3],
      endOfGame: hashParts[4] === "true"
    })
  }

  _parseRealInt(string) {
    if (string && string !== "") {
      return parseInt(string)
    }
    else {
      return null
    }
  }

  save(state) {
    this.window.document.location.hash = `${state.mapName}:${state.travelerX}:${state.travelerY}:${state.traveler}:${state.endOfGame}`
    return this
  }
}

class UrlStateStore extends UrlDebugStateStore {
  load() {
    try {
      const hash = atob(this.window.location.hash.replace(/^#/,""))
      return super._parseHash(hash)
    }
    catch (e) {
      return new State({})
    }
  }

  save(state) {
    this.window.document.location.hash = btoa(`${state.mapName}:${state.travelerX}:${state.travelerY}:${state.traveler}:${state.endOfGame}`)
    return this
  }
}

class LocalStorageStateStore extends BaseStateStore {
  constructor({window, fallBackStore}) {
    super()
    this.window = window
    this.localStorage = new LocalStorage({namespace: "GameState", window, throwOnNoLocalStorage: true})
    this.fallBackStore = fallBackStore
  }
  
  load() {
    const fallBackState = this.fallBackStore.load()
    return new State({
      mapName: this.localStorage.loadString("mapName")      || fallBackState.mapName,
      travelerX: this.localStorage.loadInt("travelerX")     || fallBackState.travelerX,
      travelerY: this.localStorage.loadInt("travelerY")     || fallBackState.travelerY,
      traveler: this.localStorage.loadString("traveler")    || fallBackState.traveler,
      endOfGame: this.localStorage.loadBoolean("endOfGame",fallBackState.endOfGame),
    })
  }

  save(state) {
    try {
      this.localStorage.save("mapName", state.mapName)
      this.localStorage.save("travelerX", state.travelerX)
      this.localStorage.save("travelerY", state.travelerY)
      this.localStorage.save("traveler", state.traveler)
      this.localStorage.save("endOfGame", state.endOfGame)
      return this
    }
    catch (e) {
      this.fallBackStore.save(state)
      this.error = e
      return this.fallBackStore
    }
  }
}

class GameState {
  constructor({window, log}) {
    window.StarlightDawn = window.StarlightDawn || {}
    window.StarlightDawn.gameState = this

    this.window = window
    this.log    = log.forClass("GameState")
    if (Runtime.env().isDev() && window.location.search.match(/debug/)) {
      console.log("Using UrlDebugStateStore")
      this.store = new UrlDebugStateStore({ window: window })
    }
    else {
      const urlStateStore = new UrlStateStore({window})
      try {
        this.store = new LocalStorageStateStore({window, fallBackStore: urlStateStore})
      }
      catch (error) {
        console.error("Problem creating LocalStorageStateStore: %o, using fallback", error)
        this.store = urlStateStore
      }
    }
    this.state = this.store.load()
  }

  isNew()       { return this.state.isNew() }
  mapName()     { return this.state.mapName }
  traveler()    { return this.state.traveler }
  travelerX()   { return this.state.travelerX }
  travelerY()   { return this.state.travelerY }
  isEndOfGame() { return this.state.endOfGame }

  travelerCoordinates(gameMap) {
    let travelerX = this.state.travelerX
    let travelerY = this.state.travelerY

    if (!travelerX || !travelerY) {
      travelerX = gameMap.travelerStartX
      travelerY = gameMap.travelerStartY
    }

    return [ travelerX, travelerY ]
  }

  clear() { this.store.clear() }

  saveState(game, change) {
    const logger = this.log.method("saveState", { change: change })
    if ( (change.type != "errorOccured") && (change.type != "loadingMap") && (change.type != "loadedMap") ) {
      logger.addDetails({ savingState: true, store: this.store.constructor.name })
      if (change.isLoadUrl()) {
        // For non-end of game, don't update the state
        if (change.endOfGame) {
          this._storeState({ endOfGame: true })
        }
      }
      else {
        this._storeState({
          mapName: game.gameMap.name,
          travelerX: game.travelerX,
          travelerY: game.travelerY,
          traveler: game.gameMap.traveler.name,
        })
      }
    }
    else {
      logger.addDetails({ savingState: false })
    }
    logger.end()
  }

  _storeState({ mapName, travelerX, travelerY, traveler, endOfGame }) {
    this.state = new State({
      mapName: mapName,
      travelerX: travelerX,
      travelerY: travelerY,
      traveler: traveler,
      endOfGame: endOfGame,
    })
    const newStore = this.store.save(this.state)
    if (newStore != this.store) {
      console.error("Problem saving state using %s: %o", this.store.constructor.name, this.store.error)
      this.store = newStore
    }
  }
}

export default GameState
