import FetchResponseNotOk from "./errors/FetchResponseNotOk"
import FetchPromiseRejected from "./errors/FetchPromiseRejected"


class LoadStarted {
  constructor(url) { this.url = url }
  isStarted()   { return true }
  isCompleted() { return false }
}

class LoadCompleted  {
  constructor(response) { this.response = response }
  isStarted()   { return false }
  isCompleted() { return true }
}

export default class Loader {
  /*
   * window - The global window object - passed to avoid this class depending directly on global state
   * forceNoCache - If true, will try its best to request the resource without caching. Useful for dev.
   * log - the global log object
   */
  constructor({window, forceNoCache, forceSlowNetwork, log}) {
    this.log              = log.forClass("Loader")
    const logger          = this.log.method("constructor", { forceNoCache: forceNoCache, forceSlowNetwork: forceSlowNetwork })
    this.forceNoCache     = !!forceNoCache
    this.forceSlowNetwork = !!forceSlowNetwork
    this.window           = window
    logger.end()
  }

  /*
   * url - the url to fetch.
   *
   * Returns either a promise that resolves to the JSON of the requested resource, or
   * if a non .ok was returned, a rejection with the response, or if fetch raised an error
   * the error that fetch raised.
   */
  load(url) {
    const logger = this.log.method("load", { url: url, forceNoCache: this.forceNoCache })
    const headers = new Headers()
    if (this.forceNoCache) {
      logger.addDetails({ forceNoCache: true })
      headers.append("pragma", "no-cache")
      headers.append("cache-control", "no-cache")
    }
    if (this.forceSlowNetwork) {
      logger.addDetails({ forceSlowNetwork: true })
      return new Promise( (resolve, reject) => {
        setTimeout( () => {
          logger.addDetails({ forcedSlowness: true })
          this._fetch(url, headers, logger).then( (json) => {
            resolve(json)
          }).catch( (error) => {
            reject(error)
          })
        }, 4000) // Should be more seconds than  animation-duration
      })
    }
    else {
      return this._fetch(url, headers, logger)
    }
  }

  _fetch(url, headers, logger) {
    return this.window.fetch(url, {
      method: "GET",
      headers: headers
    }).then( (response) => {
      if (response.ok) {
        logger.end({ ok: true })
        return response.json()
      }
      return Promise.reject(new FetchResponseNotOk(url, response))
    }).catch( (error) => {
      if (error instanceof FetchResponseNotOk) {
        logger.end(error.loggingDetails())
        return Promise.reject(error)
      }
      else {
        logger.end({ error: error.message })
        return Promise.reject(new FetchPromiseRejected(error))
      }
    })
  }
}
