/* A base error that indicates that whatever subclass of
 * this has been caught it from this app and not the JS runtime
 */
export default class BaseError extends Error {
  /*
   * Wraps the given error in a BaseError if it isn't already one.
   * This will also optionally yield the wrapped error to the given 
   * function.
   */
  static wrap(error, contextForHuman, yieldWrapped) {
    yieldWrapped ||= () => {}
    let wrappedError
    if (error instanceof BaseError) {
      wrappedError = error
      wrappedError.addContext(contextForHuman)
    }
    else {
      wrappedError = new BaseError(`${contextForHuman}: ${error.message}`, error)
    }
    yieldWrapped(wrappedError)
    return wrappedError
  }
  constructor(message, error, transient = false) {
    super(message, { cause: error })
    this.transient = transient
  }

  isTransient() { return this.transient }

  addContext(context) {
    if (context) {
      if (this.message) {
        this.message = `${context}: ${this.message}`
      }
      else {
        this.message = context
      }
    }
  }

  /*
   * To avoid issues cloning these errors inside the Performance
   * API, each error must implement this method to return a hash of
   * details suitable for including in the performance mark/measure.
   */
  loggingDetails() {
    return {
      message: this.message,
    }
  }
}
