import Runtime      from "./Runtime"
import MethodLogger from "./MethodLogger"

const NULL_PERFORMANCE = {
  mark: () => {},
  measure: () => {}
}
class Log {
  constructor({ forClassName, window }) {
    this.window = window
    this.console = this.window.console
    this.forClassName = forClassName
    this.performance = window.performance || NULL_PERFORMANCE
    this.actuallyDebugMethods = Runtime.instance().measureAllMethods
    this.actuallyMeasurePerformance = Runtime.instance().measurePerformanceCriticalMethods
  }

  /** Used to measure performance of a critical method. */
  measurePerformance(methodName, details={}) {
    if (this.actuallyMeasurePerformance) {
      return this._methodLogger(methodName,details)
    }
    else {
      return MethodLogger.nullLogger()
    }
  }

  /* Used to debug what happens inside a method, including logging details and
   * measuring performance.
   */
  method(name, details={}) {
    if (this.actuallyDebugMethods) {
      return this._methodLogger(name, details)
    }
    else {
      return MethodLogger.nullLogger()
    }
  }

  forClass(forClassName) {
    return new Log({forClassName: forClassName, window: this.window})
  }

  _methodLogger(name,details={}) {
    const logger = new MethodLogger(this.forClassName, name, this.performance, this.console)
    logger.start(details)
    return logger
  }
}

export default Log
