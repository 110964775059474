import MethodMark from "./MethodMark"

const NULL_LOGGER = {
  start: () => {},
  addDetails: () => {},
  end: () => {},
}

export default class MethodLogger {
  constructor(className, methodName, performance, console) {
    this.mark           = new MethodMark(className, methodName)
    this.currentDetails = { className: className }
    this.performance    = performance
    this.console        = console
    this.started        = false
    this.ended          = false
  }

  static nullLogger() {
    return NULL_LOGGER
  }

  start(details) {
    if (this.started) {
      return
    }
    this.addDetails(details)
    const mark = () => this.performance.mark(this.mark.start, { detail: this.currentDetails })
    try {
      mark()
    } catch (e) {
      this.console.error("Error marking %s: %s, %o", this.mark.start, e, this.currentDetails)
      if (Runtime.isDevelopment()) {
        throw e
      }
      else {
        this.console.error("Clearing details and trying again")
        this.currentDetails = {}
        mark()
      }
    }
    this.started = true
  }

  addDetails(details) {
    const safeDetails = {}
    Object.keys(details || {}).forEach( (key) => {
      const value = details[key]
      if (value) {
        if (value.loggingDetails) {
          safeDetails[key] = value.loggingDetails()
        }
        else {
          safeDetails[key] = value
        }
      }
    })
    this.currentDetails = { ...this.currentDetails, ...safeDetails }
  }

  end(details) {
    if (this.ended) {
      return
    }
    this.addDetails(details)
    const mark = () => this.performance.mark(this.mark.end, { detail: this.currentDetails })
    const measure = () => {
      this.performance.measure(this.mark.name, {
        start: this.mark.start,
        end: this.mark.end,
        detail: this.currentDetails,
      })
    }

    try {
      mark()
    } catch (e) {
      this.console.error("Error marking end %s: %s, %o", this.mark.end, e, this.currentDetails)
      if (Runtime.isDevelopment()) {
        throw e
      }
      else {
        this.console.warn("Clearing details and trying again")
        this.currentDetails = {}
        mark()
      }
    }
    try {
      measure()
    } catch (e) {
      this.console.error("Error measureing %s: %s, %o", this.mark.name, e, this.currentDetails)
      if (Runtime.isDevelopment()) {
        throw e
      }
      else {
        this.console.warn("Clearing details and trying again")
        this.currentDetails = {}
        mark()
      }
    }
    this.ended = true
  }
}
