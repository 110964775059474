const NULL_LOCAL_STORAGE = {
  setItem: () => { console.log("No localStorage, so not able to save") },
  getItem: () => { console.log("No localStorage, so not able to get") },
  removeItem: () => { console.log("No localStorage, so not able to remove") },
}
export default class LocalStorage {
  constructor({namespace, window, throwOnNoLocalStorage=false}) {
    this.namespace = namespace
    this.localStorage = window ? window.localStorage : null
    if (!this.localStorage) {
      if (throwOnNoLocalStorage) {
        throw window ? "window has no localStorage" : "window not in global namespace to access localStorage"
      }
      this.localStorage = NULL_LOCAL_STORAGE
    }
  }

  save(key, value) {
    if (value || value === false) {
      if (value.toString) {
        this.localStorage.setItem(this._key(key), value.toString())
      }
      else {
        this.localStorage.setItem(this._key(key), value)
      }
    }
    else {
      this.localStorage.removeItem(this._key(key))
    }
  }

  loadString(key) {
    return this.localStorage.getItem(this._key(key))
  }

  loadInt(key) {
    const value = this.loadString(key)
    if (value && value !== "") {
      return parseInt(value)
    }
    else {
      return null
    }
  }

  loadBoolean(key, defaultIfNotSet=false) {
    const value = this.loadString(key)
    if (value) {
      return value === "true"
    }
    else {
      return defaultIfNotSet
    }
  }

  _key(name) {
    return `StarlightDawn:${this.namespace}:${name}`
  }
}
