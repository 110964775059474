import FetchError from "./FetchError"
export default class FetchPromiseRejected extends FetchError {
  constructor(error) {
    super(error.message, error)
  }

  loggingDetails() {
    let details = super.loggingDetails()

    if (this.cause) {
      details.error = this.cause.message
      if (this.cause.constructor) {
        details.errorClass = this.cause.constructor.name
      }
    }
    return details
  }
}
