import FetchError from "./FetchError"
export default class FetchResponseNotOk extends FetchError {
  constructor(url, response) {
    super(response.statusText, null, false)
    this.url = url
    this.response = response
    if (response) {
      if ( (response.status == 429) || (response.status >= 500 && response.status < 600) ) {
        this.transient = true
      }
    }
  }

  loggingDetails() {
    const details = {
      url: this.url,
      response: {
        status: this.response.status,
        statusText: this.response.statusText,
        type: this.response.type,
      }
    }
    return { ...super.loggingDetails(), ...details }
  }
}
